/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
/* eslint-disable no-restricted-syntax */

type Env = Record<string, any>;
type EncodedEnv = Record<string, string | boolean>;

export const getHost = (env: string, isAPI = false) => {
    return env === 'local'
        ? 'http://localhost:9040'
        : env === 'prod'
          ? `https://ridb.recreation.gov${isAPI ? '' : ''}` // /ridb-v2
          : `https://ridb.r1s-${env}.com${isAPI ? '' : ''}`; // /ridb-v2
};

export const getRootHost = (env: string) => {
    return env === 'local'
        ? 'http://localhost:9040' // ui-account PORT
        : env === 'prod'
          ? `https://recreation.gov`
          : `https://r1s-${env}.com`;
};

export const envConfig = (env: string) => ({
    COPYRIGHT_YEAR: new Date().getFullYear(),
    ENCRYPTION_KEY: '9DbRdaOplABZ5mNs',
    HUB_EDIT_URL: 'https://manage.recreation.gov/internal/account/facility-info/camping',
    RIDB_API: `${getHost(env, true)}/api`,
    RIDB_ROOT: `${getHost(env, true)}`,
    RIDB_VERSION: '1.0',
    ROUTE_BASE: '', // /ridb-v2
    SITE_ROOT: `${getRootHost(env)}`,
    USE_DEFAULT_TITLE: true,
});

export const getEncodedEnvConfig = (env: string, configOverride = {}) => {
    const encodedEnvConfig: EncodedEnv = {};
    const given: Env = { ...envConfig(env), ...configOverride };

    for (const key in given) {
        if (given.hasOwnProperty(key)) {
            encodedEnvConfig[key] = JSON.stringify(given[key]);
        }
    }

    return encodedEnvConfig;
};
