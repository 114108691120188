/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-return */
import React from 'react';
import { History } from 'history';
import { Switch, useLocation } from 'react-router-dom';
import { makeSwitchRoutes } from './makeSwitchRoutes';
import Header from './containers/Header';
import Footer from './containers/Footer';
import { getRoutePropsFromPath } from './routes';
import { SarsaLocalStorageKeys } from './shared/constants/auth';
import { Globals } from './shared/globals';
import Auth from './shared/services/auth';
import { useMountEffect } from './shared/hooks/useMountEffect';
import iterateEnum = Globals.iterateEnum;

function App() {
    const location = useLocation();

    // onLoad, also assign page title
    assignPageTitleOnLoad(location.pathname);
    useMountEffect(() => {
        // clear sarsa localStorage entries on app load ->
        // sarsa will add these entries which are unused and should be removed
        iterateEnum(SarsaLocalStorageKeys, (key, value) => {
            localStorage.removeItem(value);
        });
        // If token is expired but data persists, need to clear the invalid localStorage data
        if (!Auth.isLoggedIn()) {
            Auth.clearData();
        }
    });

    return (
        <div className="dp-content-wrapper">
            <div className="dp-content">
                <Header />
                <Switch>{makeSwitchRoutes()}</Switch>
            </div>
            <Footer />
        </div>
    );
}

const assignPageTitleOnLoad = (path: string) => {
    if (process.env['USE_DEFAULT_TITLE']) {
        document.title = `${process.env['SITE_NAME']} - Camping, Cabins, RVs, Permits, Passes & More`;
    } else if (path) {
        const props = getRoutePropsFromPath(location.pathname);
        if (props) {
            document.title = props.title;
        }
    }
};

export default App;
